import React from 'react';

interface SvgIconProps {
  color?: string;
  size?: number | string;
}

const VerifiedIcon: React.FC<SvgIconProps> = ({ color = '#1baaf0', size = '1em' }) => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1wmkh38"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 36 36"
      data-testid="VerifiedIcon"
      fontSize={24}
      fill={color}
      width={size}
      height={size}
      style={{ verticalAlign: 'baseline' }}
    >
      <path d="M23 12l-2.44-2.79.34-3.69-3.61-.82-1.89-3.2L12 2.96 8.6 1.5 6.71 4.69 3.1 5.5l.34 3.7L1 12l2.44 2.79-.34 3.7 3.61.82L8.6 22.5l3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69zm-12.91 4.72-3.8-3.81 1.48-1.48 2.32 2.33 5.85-5.87 1.48 1.48z" />
    </svg>
  );
};

export default VerifiedIcon;
